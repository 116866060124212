import React from 'react';
import { Helmet } from "react-helmet";

import { 
  TopSection,
  Content,
  ContactInfo,
  WhoWeAre,
  HowWeStarted,
  OurValues,
  Experience,
  CallToAction,
} from '../../Containers/About';

import Modal from "../../Containers/WhyAgm/Modal";
const handler = {};


export const AboutUsPage = (props) => {

  let phone;
  let email;
  let locations;

  if (props.data) {

    phone = props.data.bathroom_phone;  

    email = props.data.email;
    locations = props.data.location;
  }

  let region = '';
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = ''
  }  

  const callToActionProps = {
    // text: `To find out how decades of trusted industry experience can
    // help your bathroom renovation project take flight, call or
    // reach out online today.`,
    text: (region==='vancouver' || region==='calgary') 
    ? "To find out how decades of trusted industry experience canhelp your bathroom renovation project take flight, call or reach out online today."
    : "It has never been easier to renovate a bathroom. With our 3D Design Creator you can pick all your finishes and get a quote on-the-spot. We make the journey to your new bathroom convenient, fun and most importantly, so easy!",
    className: 'bathrooms__about-us-call-to-action',
  }

  const contentData = {
    title:"Who Are We?", //"The #1 Bathroom Design & Renovation Experts",
    text:"We provide turnkey design/built bathroom renovation packages. Since 1998, AGM Renovations has created tens of thousands of unique bathrooms. As highest rated renovators, we’ve long been recognized for our quality craftsmanship, customer service, and our team of experts who can turn your old bathroom into a spa oasis."
  }

  const experienceInfo = {
    paragraph: `
      In the Home Renovation industry about 97.5% of all projects are either finished late or over budget. 
      Only 2.5% of all projects in North America are completed on time and without extra costs. 
      As one of the oldest companies in the industry, we have a proven track record of finishing bathroom renovations on time and on budget!
    `
  }

  return (
    <>
        <Helmet>
           <meta name="robots" content="noindex"/>
        </Helmet>
      <TopSection />

      <Content {...contentData} />
      <HowWeStarted />
      <WhoWeAre />
      <OurValues />
      <Experience {...experienceInfo} />

      <Modal handler={handler} />

      <ContactInfo
        phone={phone}
        email={email}
        locations={locations}
      />
      <CallToAction {...callToActionProps} />
    </>
  );
}